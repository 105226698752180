import {
  applyRuleIf,
  required,
  validateIf,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import {
  ValidationRule,
  ValidationRules,
} from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { LookupStatus } from '@rsa-digital/evo-shared-components/hooks/useLookup';
import { isQuoteOptionSelectionValid } from 'apiHelpers/quote/bundleCoverMapping';
import { graphql, useStaticQuery } from 'gatsby';
import {
  combinePetNames,
  petNameReplacer,
} from 'helpers/placeholders/dynamicPetNameHelpers';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import {
  ExcessPercentageConfirmation,
  isPromoCodeBlank,
  ProductType,
  QuoteOptions,
} from 'state/formData/quoteOptions';
import { CsErrorsMissingOnly } from 'types/contentStack';

type CsQuoteSummaryQuestionErrorMessages = {
  csPetQuoteSummaryOfYourNeedsV2: {
    confirm_vet_fees_limit_question: {
      error_messages: {
        missing: string;
        no_selected: string;
      };
    };
  };
  csPetQuoteSummaryChooseExcessV2: {
    confirm_excess_options_question_error_messages: {
      not_accepted: string;
      missing: string;
    };
  };
  csPetPromoCode: {
    promo_code: {
      error_messages: {
        unapplied_promo_code: string;
        unremoved_invalid_code: string;
      };
    };
  };
  csPetQuoteSummaryFindTheRightCoverV2: {
    vet_fee_limit_question: CsErrorsMissingOnly;
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeedsV2 {
      confirm_vet_fees_limit_question {
        error_messages {
          missing
          no_selected
        }
      }
    }
    csPetQuoteSummaryChooseExcessV2 {
      confirm_excess_options_question_error_messages {
        not_accepted
        missing
      }
    }
    csPetPromoCode {
      promo_code {
        error_messages {
          unapplied_promo_code
          unremoved_invalid_code
        }
      }
    }
    csPetQuoteSummaryFindTheRightCoverV2 {
      vet_fee_limit_question {
        error_messages {
          missing
        }
      }
    }
  }
`;

export const lowVetFeesLimitSelected = (quoteOptions: QuoteOptions): boolean =>
  quoteOptions.productType === ProductType.Vet_Fee_Limit_2000 ||
  quoteOptions.productType === ProductType.Vet_Fee_Limit_3000;

/*  We need to validate the rules for each pet in PetDetails individually. Therefore, we pass in the rules for each property in Pet,
    and apply them for each pet in PetsDetails in turn.
*/
const processHasConfirmedPercentageExcessRules = <T>(
  rules?: ValidationRule<T, ExcessPercentageConfirmation>[]
): ValidationRule<T, QuoteOptions>[] | undefined =>
  rules?.map(({ validityCondition, ...rest }) => ({
    ...rest,
    validityCondition: (v, data, index) =>
      validityCondition(v, data.excessPercentageConfirmations[index]),
  }));

const useQuoteSummaryRules = (): ValidationRules<QuoteOptions> => {
  const {
    csPetQuoteSummaryOfYourNeedsV2: {
      confirm_vet_fees_limit_question: { error_messages: vetFeesLimitErrorMessages },
    },
    csPetQuoteSummaryChooseExcessV2: {
      confirm_excess_options_question_error_messages: excessOptionsErrorMessages,
    },
    csPetPromoCode: {
      promo_code: { error_messages: promoCodeErrorMessages },
    },
    csPetQuoteSummaryFindTheRightCoverV2: {
      vet_fee_limit_question: { error_messages: vetFeeLimitErrorMessages },
    },
  } = useStaticQuery<CsQuoteSummaryQuestionErrorMessages>(query);

  const quote = useCurrentQuote();
  const petNames = quote?.petInfos?.map((pet) => pet.petName);
  const combinedPetNames = combinePetNames(petNames ?? []);
  const vetFeeLimitMissingErrorMessage = petNameReplacer(
    combinedPetNames,
    vetFeeLimitErrorMessages.missing
  );

  const replaceQuotePlaceholders = replacePlaceholdersRichText(quotePlaceholders, quote);
  const vetFeesLimitMissingErrorMessage = replaceQuotePlaceholders(
    vetFeesLimitErrorMessages.missing
  );

  return {
    hasConfirmedVetFeesLimit: validateIf(lowVetFeesLimitSelected, [
      required(vetFeesLimitMissingErrorMessage),
      {
        validityCondition: (value) => value !== false,
        errorMessage: vetFeesLimitErrorMessages.no_selected,
      },
    ]),
    excessPercentageConfirmations: {
      hasConfirmedExcessPercentage: processHasConfirmedPercentageExcessRules([
        applyRuleIf(
          () => isQuoteOptionSelectionValid(quote.quoteOptions),
          required(excessOptionsErrorMessages.missing)
        ),
        {
          validityCondition: (value) => value !== false,
          errorMessage: excessOptionsErrorMessages.not_accepted,
          onlyValidateAfterSubmission: false,
        },
      ]),
    },
    promoCode: [
      {
        validityCondition: (value, data) =>
          isPromoCodeBlank(data) || value.status !== LookupStatus.Pending,
        errorMessage: promoCodeErrorMessages.unapplied_promo_code,
        onlyValidateAfterSubmission: true,
      },
      {
        validityCondition: (value, data) =>
          isPromoCodeBlank(data) || value.status === LookupStatus.Success,
        errorMessage: promoCodeErrorMessages.unremoved_invalid_code,
        onlyValidateAfterSubmission: true,
      },
    ],
    productType: [required(vetFeeLimitMissingErrorMessage)],
  };
};

export default useQuoteSummaryRules;
