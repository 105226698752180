import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import PanelWithActionLink from 'components/PanelWithActionLink';
import RichTextWithModal from 'components/RichTextWithModal';
import LabelledDates from './LabelledDates';

export const StyledGrid = styled(Grid)`
  flex-direction: column;
`;

export const RichTextWithMargin = styled(RichTextWithModal)`
  margin-top: ${spacing(3)};

  p {
    ${fonts.paragraphSmall}
  }

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(4)};
    
    p{
      ${fonts.paragraph}
    }
  `}
`;

export const StyledPanelWithActionLink = styled(PanelWithActionLink)`
  && {
    border: none;
    background-color: ${colors.core06};
  }
`;

export const PricePanelHeading = styled.h3`
  ${fonts.headingXSmall}
  margin: ${spacing(4)} 0 ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    ${fonts.headingSmall}
  `}
`;

export const LabelledDatesWithMargin = styled(LabelledDates)`
  margin-top: ${spacing(6)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(9)};
  `}
`;
